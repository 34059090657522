import $ from 'jquery'

export default class chatWindowScroll {
  constructor (element) {
    this.$element = $(element)
    this.$scrollElement = $(element).find('.conversationMessageScrollContainer')
    const height = this.$scrollElement.innerHeight()
    this.$element.scrollTop(height)
  }
}

