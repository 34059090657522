import $ from 'jquery'

export default class chatForm {
  constructor (element) {
    this.$element = $(element)

    this.$textarea = this.$element.find('textarea')
    this.$form = this.$element
    this.$submitButton = this.$element.find('button')
    this.$submitButtonIcon = this.$submitButton.find('i')
    this.$textarea.on('keypress', this.handleKeypress.bind(this))
    this.$form.on('submit', this.handleSubmit.bind(this))
    this.heightLimit = 100

    this.iconClassesOnSubmit = 'fa-solid fa-circle-notch fa-spin'
  }

  handleSubmit (event) {
    this.$submitButton.prop('disabled', true)
    this.$submitButtonIcon.attr('class', this.iconClassesOnSubmit)
  }

  handleKeypress (event) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault()
      this.$form.trigger('submit')
    }

    this.$textarea.css({ 'height': `${Math.min(this.$textarea.prop('scrollHeight'), this.heightLimit)}px` })
  }
}
